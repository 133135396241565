import Vue from 'vue'

// axios
import axios from 'axios'

const axiosOptions = {}
const axiosIns = axios.create(axiosOptions.value)

Vue.prototype.$http = axiosIns

export default axiosIns
